import { environment } from './../../environments/environment';

const BASE_URL = environment.apiEndpoint;
const API_URL = BASE_URL + '/api';

const PROVIDER_API_URL = API_URL + '/careteam/v1';

export const API_ENDPOINTS = {
    update_password: BASE_URL + '/user/password',
    avatar: API_URL + '/avatar',
    zipcode: API_URL + '/zipcode/{zipcode}',
    ethnicity: API_URL + '/ethnicity',
    caretypes: API_URL + '/care-types',
    check_email: API_URL + '/check-email',
    getOtp: API_URL + '/file/generateOtp',
    downloadFile: API_URL + '/file/download',
    permission: PROVIDER_API_URL + '/permission',
    states_lists: API_URL + '/lists/states',
    auth: {
        csrf_cookie: BASE_URL + '/sanctum/csrf-cookie',
        login: BASE_URL + '/careteam/login',
        logout: BASE_URL + '/logout',
        forgot_password: BASE_URL + '/forgot-password',
        reset_password: BASE_URL + '/reset-password',
        set_password: API_URL + '/set-password',
        is_loggedin: API_URL + '/careteam/is-loggedin',
    },
    provider: {
        me: PROVIDER_API_URL + '/me',
        meBio: PROVIDER_API_URL + '/me/details',
        updateBio: PROVIDER_API_URL + '/me/details/update',
        dashboard: PROVIDER_API_URL + '/dashboard',
        criticalAlertEmailContent: PROVIDER_API_URL + '/critical-email',
        subjectList: PROVIDER_API_URL + '/subjects',
        subjectInterventionList: PROVIDER_API_URL + '/subject-interventions',
        subjectInterventionCCMActionList: PROVIDER_API_URL + '/ccm-subject-interventions/get-ccm-actions/{id}',     
        subjectInterventionCCMActionDelete: PROVIDER_API_URL + '/ccm-subject-interventions/remove-ccm-action/{id}',     
        notificationPreferenceUpdate: PROVIDER_API_URL + '/notification-preferences',
        custom_notes: {
            list: PROVIDER_API_URL + '/note-templates',
            create: PROVIDER_API_URL + '/note-templates',
            delete: PROVIDER_API_URL + '/note-templates/{id}',
        },
        patients: {
            list: PROVIDER_API_URL + '/patients',
            detail: PROVIDER_API_URL + '/patients/' + '{id}',
            detail_info: PROVIDER_API_URL + '/patients/' + '{id}' + '/detail',
            ribbon_detail: PROVIDER_API_URL + '/patients/' + '{id}' + '/ribbon-detail',
            update_patient_status: PROVIDER_API_URL + '/patients/' + '{id}' + '/update-patient-status',
            edit_detail_info: PROVIDER_API_URL + '/patients/' + '{id}' + '/detail',
            discharge: PROVIDER_API_URL + '/patients/' + '{id}' + '/discharge',
            update: PROVIDER_API_URL + '/patients/{id}',
            profile: PROVIDER_API_URL + '/patients/' + '{id}' + '/profile',
            toggleAutomatedSms: PROVIDER_API_URL + '/patients/toggleAutomatedSms/' + '{id}',
            notificationFetch: PROVIDER_API_URL + '/get-patient-notification-list/{id}',
            updateNotificationStatus:  PROVIDER_API_URL + '/update-patient-notification-list',
            reminderTimeUpdate: PROVIDER_API_URL + '/update-patient-reminder-time',
            sendBulkSms: PROVIDER_API_URL + '/patients/sendBulkSms',
            devices: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/devices',
                store: PROVIDER_API_URL + '/patient-devices',
                destroy: PROVIDER_API_URL + '/patient-devices/{id}',
                hologram_status: PROVIDER_API_URL + '/devices/{serial}/hologram-info'
            },
            call_logs: PROVIDER_API_URL + '/patients/' + '{id}' + '/call-logs',
            text_logs: PROVIDER_API_URL + '/patients/' + '{id}' + '/text-logs',
            send_text: PROVIDER_API_URL + '/patients/' + '{id}' + '/send-text',
            update_clinic_notes: PROVIDER_API_URL + '/patients/' + '{id}' + '/clinic-notes',
            moving_avg_list: PROVIDER_API_URL + '/patients/moving-average-list',
            careteam_members: PROVIDER_API_URL + '/patients/{id}/careteam-members',
            notes: {
                list: PROVIDER_API_URL + '/patients/{id}/notes',
                create: PROVIDER_API_URL + '/patients/{id}/notes',
                show: PROVIDER_API_URL + '/patients/{id}/notes/{noteId}',
                show_alert: PROVIDER_API_URL + '/patients/{id}/critical-reading-note/{type}/{reading}',
            },
            time_log: {
                create: PROVIDER_API_URL + '/patients/{id}/timer-logs',
                list: PROVIDER_API_URL + '/patients/{id}/timer-logs',
                createClinicalMinutes: PROVIDER_API_URL + '/patients/{id}/clinicalMinutes',            
            },
            blood_pressure: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/blood-pressure',
            },
            blood_glucose: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/blood-glucose',
            },
            cholesterol: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/cholesterol',
                create: PROVIDER_API_URL + '/patients/' + '{id}' + '/cholesterol',
            },
            weight: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/weight',
                update_height: PROVIDER_API_URL + '/user-height/{id}',
            },
            oxygen_saturation: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/oxygen-saturation',
            },
            temperature: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/temperature',
            },
            care_plan: {
                days_list: PROVIDER_API_URL + '/patients/' + '{id}' + '/care-plan',
                conversation: PROVIDER_API_URL + '/patients/{id}/care-plan/{patientCareplanId}/day/{day}',
            },
            sleep: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/sleep',
            },
            heart_rate: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/heart-rate',
            },
            nutrition: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/nutrition',
            },
            distance: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/distance',
            },
            steps: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/steps',
            },
            calories: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/calories',
            },
            ecg: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/ecg',
            },
            report: {
                list: PROVIDER_API_URL + '/patients/' + '{id}' + '/reports',
                filter: PROVIDER_API_URL + '/patients/' + '{id}' + '/reports/filter',
                profile: PROVIDER_API_URL + '/patients/' + '{id}' + '/getProfileReport',
                reportData: PROVIDER_API_URL + '/patients/' + '{id}' + '/getDataReport',
                saveReport: PROVIDER_API_URL + '/patients/' + '{id}' + '/saveReport',
                reportTrend: PROVIDER_API_URL + '/patients/' + '{id}' + '/getTrendReport',
                ccmReport: PROVIDER_API_URL + '/patients/' + '{id}' + '/report/ccm-report',
                engagmentReportRpm: PROVIDER_API_URL + '/patients/' + '{id}' + '/report/rpm-engagement-report',
                engagmentReportCcm: PROVIDER_API_URL + '/patients/' + '{id}' + '/report/ccm-engagement-report' 
            },
            alerts: PROVIDER_API_URL + '/patients/alerts',
            alerts_count: PROVIDER_API_URL + '/patients/alerts/count/{id}',
            patient_alerts: PROVIDER_API_URL + '/patients/' + '{id}' + '/patient-alerts',
            device_list: PROVIDER_API_URL + '/patients/device/list',     
            shipping_device_list: PROVIDER_API_URL + '/patients/device/list/shipping', 
            create_shipping_device_list: PROVIDER_API_URL + '/patients/device/list/shipping/create', 
            update_shipping_device_list: PROVIDER_API_URL + '/patients/device/list/shipping/update/{id}',
            showswitch_shipping_device_list: PROVIDER_API_URL + '/patients/device/list/shipping/showswitch', 
            switch_shipping_device_list: PROVIDER_API_URL + '/patients/device/list/shipping/switch',
            shipping_device_list_check_patient: PROVIDER_API_URL + '/patients/device/list/shipping/patient', 
            shipping_device_list_check_devicetype: PROVIDER_API_URL + '/patients/device/list/shipping/deviceType',          
            enrollment: {
                index: PROVIDER_API_URL + '/patient-enrollment',
                store: PROVIDER_API_URL + '/patient-enrollment',
                update: PROVIDER_API_URL + '/patient-enrollment/{id}',
                show: PROVIDER_API_URL + '/patient-enrollment/{id}',
                destroy: PROVIDER_API_URL + '/patient-enrollment/{id}',
                physicians: PROVIDER_API_URL + '/patient-enrollment/physicians',
                careteams: PROVIDER_API_URL + '/patient-enrollment/careteams',
                archive_patients: PROVIDER_API_URL + '/archive-patients',
                available_devices: PROVIDER_API_URL + '/patient-enrollment/available-devices',
                icd_10_codes: PROVIDER_API_URL + '/patient-enrollment/icd-10-codes',
                send_email: PROVIDER_API_URL + '/patient-enrollment/{id}/send-email',
                send_text: PROVIDER_API_URL + '/patient-enrollment/{id}/send-text',
                document_sheet: PROVIDER_API_URL + '/patient-enrollment/document-sheet',
                document_other: PROVIDER_API_URL + '/patient-enrollment/document-other',
                document_upload: PROVIDER_API_URL + '/patient-document/document-upload',
                home_healths: PROVIDER_API_URL + '/patient-enrollment/home-healths',
            },
            tasks: {
                list: PROVIDER_API_URL + '/patients/{id}/tasks',
            },
            transaction: {
                history: PROVIDER_API_URL + '/patients/transaction/history',
            },
            chat:
            {
                send: PROVIDER_API_URL + '/patients/{id}/chat-messages',
                list: PROVIDER_API_URL + '/patients/{id}/chat-messages',
                unseenMessage: PROVIDER_API_URL + '/patients/{id}/unseenMessage',
                unseenMessageList: PROVIDER_API_URL + '/patients/unseenMessageList'
            },
            readings: {
                not_addressed: PROVIDER_API_URL + '/patient-readings/not-addressed'
            },
            screening: {
                list: PROVIDER_API_URL + '/patients/{id}/screenings',
                create: PROVIDER_API_URL + '/patients/{id}/screenings',
                show: PROVIDER_API_URL + '/patients/{id}/screenings/{screeningId}',
            },
            ccm_careplan: {
                list: PROVIDER_API_URL + '/patients/{id}/ccm-careplans',
                create: PROVIDER_API_URL + '/patients/{id}/ccm-careplans',
                update: PROVIDER_API_URL + '/patients/{id}/ccm-careplans/{careplanId}',
                show: PROVIDER_API_URL + '/patients/{id}/ccm-careplans/{careplanId}',
                completed: PROVIDER_API_URL + '/patients/{id}/ccm-careplans/{careplanId}/completed',
            },
            complex_ccm: {
                list: PROVIDER_API_URL + '/patients/{id}/complex-ccm',
                create: PROVIDER_API_URL + '/patients/{id}/complex-ccm',
                update: PROVIDER_API_URL + '/patients/{id}/complex-ccm/{complexCcmId}',
                show: PROVIDER_API_URL + '/patients/{id}/complex-ccm/{complexCcmId}'
            },
            medicaid_details: PROVIDER_API_URL + '/patients/' + '{id}' + '/medicaid-details',
            check_ccm_goals: PROVIDER_API_URL + '/patients/{id}/check-ccm-goals',
            custom_vital_alerts: {
                list: PROVIDER_API_URL + '/patients/{id}/custom-vital-alerts',
                create: PROVIDER_API_URL + '/patients/{id}/custom-vital-alerts',
                update: PROVIDER_API_URL + '/patients/{id}/custom-vital-alerts/{customVitalAlertId}',
                delete: PROVIDER_API_URL + '/patients/{id}/custom-vital-alerts/{customVitalAlertId}',
            },
            generate_password: PROVIDER_API_URL + '/patients/{id}/generate-password'
        },
        tasks: {
            create: PROVIDER_API_URL + '/tasks',
            update: PROVIDER_API_URL + '/tasks/{id}',
            assigned_tasks: PROVIDER_API_URL + '/assigned-tasks',
            delete: PROVIDER_API_URL + '/tasks/{id}',
        },
        twilio: {
            video: {
                access_token: PROVIDER_API_URL + '/twilio/video/access-token'
            },
            voice: {
                token: PROVIDER_API_URL + '/twilio/voice/token'
            }
        },
        video_call: {
            index: PROVIDER_API_URL + '/video-call',
            store: PROVIDER_API_URL + '/video-call',
            update: PROVIDER_API_URL + '/video-call/{id}',
            updateSeen: PROVIDER_API_URL + '/video-call/updateSeen',
        },
        reports: {
            non_adherence: PROVIDER_API_URL + '/reports/non-adherence',
            billing: PROVIDER_API_URL + '/reports/billing',
            medicaid_billing: PROVIDER_API_URL + '/reports/medicaid-billing',
            billing_ccm: PROVIDER_API_URL + '/reports/billing-ccm',
            billing_stats: PROVIDER_API_URL + '/reports/billing-stats',
            billing_invoice: PROVIDER_API_URL + '/reports/billing/invoice',
            medicaid_billing_invoice: PROVIDER_API_URL + '/reports/medicaid-billing/invoice',
            billing_ccm_invoice: PROVIDER_API_URL + '/reports/billing-ccm/invoice',
            billing_filter: PROVIDER_API_URL + '/reports/billing/filter',
            enrolment_practice_filter: PROVIDER_API_URL + '/reports/billing/enrolment-practices',
            stratifying: {
                dashboard: PROVIDER_API_URL + '/report/stratifying',
                csv: PROVIDER_API_URL + '/report/stratifying/csv',
            },
            outcomes: {
                dashboard: PROVIDER_API_URL + '/report/outcomes',
                notes: PROVIDER_API_URL + '/report/outcomes/notes',
                notesDetails: PROVIDER_API_URL + '/report/outcomes/notes/details',
                dashboardV2: PROVIDER_API_URL + '/report/outcomes/v2',
                patientList: PROVIDER_API_URL + '/report/outcomes/v2/patientList',
            },
            monthlyOutcomes:
            {
                dashboard: PROVIDER_API_URL + '/report/monthly-outcomes',
                getPractice: PROVIDER_API_URL + '/report/outcomes/practice',
            },
            performanceScoreCard:
            {
                dashboard: PROVIDER_API_URL + '/report/performanceScoreCard',
                referrals: PROVIDER_API_URL + '/report/performanceScoreCardV2',
            },
            readings_compliance: PROVIDER_API_URL + '/reports/readings-compliance',
            admissions: PROVIDER_API_URL + '/reports/admissions',
            physicians: PROVIDER_API_URL + '/reports/physicians'
        },
        new_patients_list: PROVIDER_API_URL + '/newly-registered-patients',
        followup_comments: {
            list: PROVIDER_API_URL + '/patients/{patientId}/followup-comments',
            create: PROVIDER_API_URL + '/patients/{patientId}/followup-comments',
        },
        patient_actions: {
            list: PROVIDER_API_URL + '/patients/{patientId}/actions',
            create: PROVIDER_API_URL + '/patients/{patientId}/actions',
            update_action_due_date: PROVIDER_API_URL + '/patients/{patientId}/actions-due-date',
        },
        group: {
            org_tree: PROVIDER_API_URL + '/group/organizational-tree',
        },
        work_list: PROVIDER_API_URL + '/work-list',
        work_list_add_note: PROVIDER_API_URL + '/work-list/add-note',
        quick_enrollment : {
            search: PROVIDER_API_URL + '/eligible-patient/search',
            update: PROVIDER_API_URL + '/eligible-patient-enrollment/{id}',
            exist: PROVIDER_API_URL + '/eligible-patient/check-exist',
        },
        patient_insurance_list: PROVIDER_API_URL + '/patient-insurance-list'
    }
};
